/**
 * Status Colors
 * @enum {string}
 */
export const StatusColors = {
  DRAFT: 'secondary',
  PENDING_PAYMENT: 'warning',
  PENDING_DELIVERY: 'warning',
  INCOMPLETE: 'danger',
  PAYMENT_ERROR: 'danger',
  ERROR: 'danger',
  PROCESSING: 'info',
  OUT_FOR_DELIVERY: 'info',
  COMPLETE: 'success',
  DELIVERED: 'success',
  COMPLETED: 'success',
  CANCELLED: 'danger',
  REFUNDED: 'dark',
  PAID: 'success',
  CONFIRM: 'success',
  PENDING: 'warning',
};

/**
 * Status Colors
 * @enum {string}
 */
export const PromosColor = {
  DISCOUNT: 'secondary',
  SHIPPING: 'info',
  BUNDLE: 'warning',
};

/**
 * Product Status Colors
 * @enum {string}
 */
export const ProductStatusColor = {
  OUT_OF_STOCK: 'danger',
  READY_TO_SHIP: 'info',
  PRE_ORDER: 'warning',
};

/**
 * Delivery status
 * @enum {string}
 */
export const DeliveryStatus = {
  PENDING_DELIVERY: 'pending delivery',
  OUT_FOR_DELIVERY: 'out for delivery',
  DELIVERED: 'delivered',
};

/**
 * Delivery option
 * @enum {string}
 */
export const DeliveryOptions = {
  ALL: 'all',
  READY: 'ready',
  UNSURE: 'unsure',
};

/**
 * Delivery Date Status
 * @enum {string}
 */
export const DeliveryDateStatus = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
};

/**
 * Order Delivery Status
 * @enum {string}
 */
export const OrderDeliveryStatus = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
};

/**
 * Allocation stock status
 * @enum {string}
 */
export const AllocationStockStatus = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
};

/**
 * Order status
 * @enum {string}
 */
export const OrderStatus = {
  DRAFT: 'draft',
  PENDING_PAYMENT: 'pending payment',
  PAYMENT_ERROR: 'payment error',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  REFUNDED: 'refunded',
};

/**
 * Product Collection
 * @enum {string}
 */
export const ProductCollections = {
  Mattress: 'Mattress',
  Pillow: 'Pillow',
  BedFrame: 'Bed Frame',
  Headboard: 'Headboard',
  HeadboardLeather: 'Headboard Leather',
  HeadboardFabric: 'Headboard Fabric',
  BedSheet: 'Bed Sheet',
  SleepKit: 'Sleep Kit',
  Accessories: 'Accessories',
  MiscCharges: 'Misc Charges',
};
/**
 * Status Type
 * @enum {string}
 */
export const StatusType = {
  STANDARD: 'Standard',
  CUSTOM: 'Custom',
};

/**
 * Product Status
 * @enum {string}
 */
export const ProductStatus = {
  OUT_OF_STOCK: 'Out of Stock',
  READY_TO_SHIP: 'Ready to Ship',
  PRE_ORDER: 'Pre-Order',
};

/**
 * Currencies
 * @enum {string}
 */
export const Currencies = {
  SGD: 'SGD',
};

/**
 * Promotion Stack Type
 * @enum {string}
 */
export const PromoStackTypes = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};

/**
 * Promotion Types
 * @enum {string}
 */
export const PromoTypes = {
  DISCOUNT: 'discount',
  SHIPPING: 'shipping',
  BUNDLE: 'bundle',
};

/**
 * Promotion Discount Applies To
 * @enum {string}
 */
export const DiscountAppliesTo = {
  PRODUCTS: 'products',
  ENTIRE: 'entire',
  COLLECTIONS: 'collection',
};

/**
 * Promotion Discount Types
 * @enum {string}
 */
export const DiscountTypes = {
  FIXED: 'fixed',
  PERCENT: 'percent',
};

/**
 * Promotion Discount Minimum Requirement Types
 * @enum {string}
 */
export const DiscountMinTypes = {
  NONE: 'none',
  PURCHASE_AMOUNT: 'purchaseAmount',
  PURCHASE_QTY: 'purchaseQty',
};

/**
 * Promotion Eligibility Types
 * @enum {string}
 */
export const PromoEligibilityTypes = {
  ALL: 'all',
  SPECIFIC: 'specific',
};

/**
 * Promotion Shipping Types
 * @enum {string}
 */
export const PromoShippingTypes = {
  ALL: 'all',
  SPECIFIC: 'specific',
};

/**
 * Promotion Shipping Types
 * @enum {string}
 */
export const ShippingCountries = {
  SINGAPORE: 'Singapore',
  MALAYSIA: 'Malaysia',
};

/**
 * Promotion Bundle Buy Types
 * @enum {string}
 */
export const PromoBundleBuyTypes = {
  PRODUCTS: 'products',
  COLLECTIONS: 'collections',
};

/**
 * Promotion Bundle Get Types
 * @enum {string}
 */
export const PromoBundleGetTypes = {
  PRODUCTS: 'products',
  COLLECTIONS: 'collections',
  DISCOUNT: 'discount',
};

/**
 * Promotion Bundle Get SubTypes
 * @enum {string}
 */
export const PromoBundleGetSubTypes = {
  FREE: 'free',
  FIXED: 'fixed',
  PERCENT: 'percent',
};

/**
 * Payment Types
 * @enum {string}
 */
export const PaymentTypes = {
  STRIPE: 'stripe',
  HOOLAH: 'hoolah', // DEPRECATED, do not use
  GRABPAY: 'grabpay',
  ATOME: 'atome',
  HITPAY: 'hitpay',
  SHOPBACK: 'shopback',
  PAYNOW: 'paynow',
  KRIS: 'kris+',
  OTHER: 'other',
};

/**
 * Referral types in orders
 * @enum {string}
 */
export const ReferralOrderTypes = {
  DISCOUNT: 'discount',
  REBATE: 'rebate',
};

/**
 * Referral sub types in orders
 * @enum {string}
 */
export const ReferralOrderSubTypes = {
  PERCENT: 'percent',
  FIXED: 'fixed',
};

/**
 * Status for referral rebate (Not to be stored in DB)
 * @enum {string}
 */
export const ReferralEarnStatus = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  PROCESSING: 'processing',
  PAID: 'paid',
  ERROR: 'error',
};

/**
 * In days, how long after purchase can the rebate be valid for withdrawal
 * @enum {string}
 */
export const ReferralWithdrawConditions = {
  MATTRESS: 100,
  DEFAULT: 30,
};

/**
 * The referral withdraw mode supported
 * @enum {string}
 */
export const ReferralWithdrawMode = {
  PAYNOW: 'paynow',
  BANK: 'bank',
};

/**
 * Period selections
 * @enum {string}
 */
export const PeriodSelections = {
  TODAY: 'Today',
  THISWEEK: 'This Week',
  LASTWEEK: 'Last Week',
  THISMONTH: 'This Month',
  LASTMONTH: 'Last Month',
};

/**
 * Payment Status
 * @enum {string}
 */
export const PaymentStatus = {
  PENDING: 'pending',
  SUCCESS: 'success',
  PAYMENT_ERROR: 'payment error',
  REFUNDED: 'refunded',
};

/**
 * Payment Status Colors
 * @enum {string}
 */
export const PaymentStatusColors = {
  PENDING: 'info',
  SUCCESS: 'success',
  PAYMENT_ERROR: 'danger',
  REFUNDED: 'warning',
};

/**
 * Status Colors
 * @enum {string}
 */
export const CustomerType = {
  REGISTERED: 'Registered',
  GUEST: 'Guest',
};

/**
 * @enum {string}
 */
export const ProductAssetKey = {
  DESIGN: 'design',
  MATERIAL: 'material',
  COLOUR: 'colour',
};

/**
 * @enum {string}
 */
export const MattressVariants = {
  SINGLE: 'Single',
  SUPERSINGLE: 'Super Single',
  QUEEN: 'Queen',
  KING: 'King',
};

/**
 * file size in KB
 * @enum {number}
 */
export const ProductImage = {
  MAX_SIZE: 300, // in kb
};
