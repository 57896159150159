import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormInput,
  InputGroup,
  InputGroupAddon,
  Card,
  CardImg,
  CardBody,
  Tooltip,
} from 'shards-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { warning } from 'react-toastify-redux';
import moment from 'moment';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { arrayMoveImmutable } from 'array-move';
import {
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
} from '../../store/actions/products';
import { MainStage } from '../../components/layout';
import VariantGroupModal from './VariantGroupModal';
import VariantThumbnailModal from './VariantThumbnailModal';
import ProductAssetModal from './ProductAssetModal';
import ProductAssetTab from './ProductAssetTab';
import ProductSubVariant from './ProductSubVariant';
import ProductVariant from './ProductVariant';
import {
  FormCard,
  FormGroup,
  DialogButton,
  MaterialIcon,
} from '../../components/common';
import {
  readFileInBase64,
  getFileType,
  uploadFile,
  getDownloadUrl,
  isFileSizeValid,
} from '../../utils/media';
import { hasAccess } from '../../utils/auth';
import {
  ProductCollections,
  ProductStatus,
  StatusType,
  ProductAssetKey,
  MattressVariants,
  ProductImage,
} from '../../constants';

const enableSubVariant =
  process.env.REACT_APP_ENABLE_SUBVARIANT_PRODUCT === 'true';

const customVariantProductIds = [
  process.env.REACT_APP_PRODUCT_HEADBOARD_SIDEFRAME_ID,
  process.env.REACT_APP_PRODUCT_HEADBOARD_SIDEFRAME_BOX_SPRING_ID,
  process.env.REACT_APP_PRODUCT_STORAGE_BASE_ID,
  process.env.REACT_APP_PRODUCT_EGYPTIAN_COTTON_BEDDING_SET_ID,
  process.env.REACT_APP_PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET_ID,
  process.env.REACT_APP_PRODUCT_EGYPTIAN_COTTON_DUVET_COVER_ID,
  process.env.REACT_APP_PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET_ID,
  process.env.REACT_APP_PRODUCT_STORAGE_ADJUSTABLE_BASE_ID,
  process.env.REACT_APP_PRODUCT_TENCEL_BEDDING_SET_ID,
  process.env.REACT_APP_PRODUCT_TENCEL_PILLOW_CASE,
  process.env.REACT_APP_PRODUCT_TENCEL_DUVET_COVER,
  process.env.REACT_APP_PRODUCT_TENCEL_FITTED_SHEET,
  process.env.REACT_APP_PRODUCT_SIDEFRAMES_ONLY,
  process.env.REACT_APP_PRODUCT_STORAGE_BASE_NO_HEADBOARD,
];

const ProductForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [videoPath, setVideoPath] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState({});
  const [productState, setProductState] = useState(null);
  const [selectedVariantGroup, setSelectedVariantGroup] = useState(null);
  const [selectedProductAsset, setSelectedProductAsset] = useState(null);
  const [variantsState, setVariantsState] = useState([]);
  const [openAddVariantModal, setOpenAddVariantModal] = useState(false);
  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const [openThumbnailModal, setOpenThumbnailModal] = useState(false);
  const [isSubVariantProduct, setIsSubVariantProduct] = useState(false);
  const [selectedVariantThumbnail, setSelectedVariantThumbnail] = useState({});
  const [variantGroups, setVariantGroups] = useState([]);
  const [productAssets, setProductAssets] = useState([]);
  const [dragAndDrop, setDragAndDrop] = useState({
    originalOrder: [],
    draggedFrom: null,
    draggedTo: null,
  });
  const isLoading = useSelector(({ ui }) => ui.isLoading);
  const productData = useSelector(({ products: { product } }) => product || {});
  const { control, handleSubmit, errors, getValues, setValue } = useForm();
  const canUpdate = hasAccess('products', 'update');
  const canCreate = hasAccess('products', 'create');

  const productTypeOptions = Object.keys(ProductCollections).map((key) => {
    return { value: ProductCollections[key], label: ProductCollections[key] };
  });

  const productStatusOptions = Object.keys(ProductStatus).map((key) => {
    return { value: ProductStatus[key], label: ProductStatus[key] };
  });
  const customProductStatusOptions = [
    { value: ProductStatus.PRE_ORDER, label: ProductStatus.PRE_ORDER },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getProduct(id));
    } else {
      const newProduct = {
        name: '',
        collection: '',
        displayInStore: false,
        shippable: true,
        variants: [],
        media: [],
      };
      setProductState(newProduct);
    }
  }, [id]);

  useEffect(() => {
    if (id && productData && productData.id === id) {
      productData.variants.map((v) => {
        if (v.statusDate && v.statusDate.seconds)
          v.statusDate = moment.unix(v.statusDate.seconds).toDate();
        if (!v.rowId) v.rowId = uuidv4();
        return v;
      });
      setProductState(productData);
      if (productData.variants) setVariantsState(productData.variants);
      if (productData.media) setAttachedFiles(productData.media);

      if (customVariantProductIds.includes(id) && enableSubVariant) {
        setIsSubVariantProduct(true);
        if (productData.variants) {
          const variantGroupsData = [];
          productData.variants.forEach((v) => {
            if (v.subVariants && v.subVariants.length) {
              const design = v.subVariants.find(
                (s) => s.key === ProductAssetKey.DESIGN
              );
              const material = v.subVariants.find(
                (s) => s.key === ProductAssetKey.MATERIAL
              );
              const colour = v.subVariants.find(
                (s) => s.key === ProductAssetKey.COLOUR
              );
              const variantGroup = variantGroupsData.find(
                (g) =>
                  g.statusType === v.statusType &&
                  g.design.key === design.key &&
                  g.design.value === design.value &&
                  g.material.key === material.key &&
                  g.material.value === material.value &&
                  g.colour.key === colour.key &&
                  g.colour.value === colour.value
              );
              if (variantGroup) {
                variantGroup.variants.push(v);
              } else {
                const newVariantGroup = {
                  open: !variantGroups.length,
                  statusType: v.statusType,
                  meta: v.meta,
                  design,
                  material,
                  colour,
                  variants: [v],
                  thumbnail: v.thumbnail,
                };
                variantGroupsData.push(newVariantGroup);
              }
            }
          });
          console.log('variantGroupsData', variantGroupsData);
          setVariantGroups([...variantGroupsData]);

          if (productData.assets && productData.assets.length) {
            setProductAssets(productData.assets);
          }
        }
      } else if (productData.variants && productData.variants.length)
        setThumbnail({ thumbnail: productData.variants[0].thumbnail });
    }
  }, [productData]);

  // for non sub variant
  const variantValueChange = (e, index, field) => {
    if (e.target) variantsState[index][field] = e.target.value;
    else variantsState[index][field] = e;
    setVariantsState([...variantsState]);
  };

  const variantPriceChange = (e, index, field) => {
    if (e.target) variantsState[index].prices[0][field] = e.target.value;
    else variantsState[index].prices[0][field] = e;
    setVariantsState([...variantsState]);
  };

  const variantQtyChange = (e, index, field) => {
    const data = getValues();
    let reservedQty = 0;
    let onHandQty = 0;
    if (field === 'onHandQty') {
      onHandQty = parseFloat(e.target.value);
      reservedQty = parseFloat(data[`variants[${index}].reservedQty`]) || 0;
    } else {
      onHandQty = parseFloat(data[`variants[${index}].onHandQty`]) || 0;
      reservedQty = parseFloat(e.target.value);
    }
    const availableQty = onHandQty - reservedQty;
    setValue(`variants[${index}].availableQty`, availableQty);
    variantsState[index].availableQty = availableQty;
    setVariantsState([...variantsState]);

    variantValueChange(e, index, field);
  };

  const variantStatusChange = (e, index) => {
    variantsState[index].status = e.target.value;
    setVariantsState([...variantsState]);
  };

  const variantGroupValueChange = (e, gIndex, index, field) => {
    if (e.target) variantGroups[gIndex].variants[index][field] = e.target.value;
    else variantGroups[gIndex].variants[index][field] = e;
    setVariantGroups([...variantGroups]);
  };

  const variantGroupDateNoteChange = (e, gIndex, index, field) => {
    const targetItem = e.target ? e.target.value : e;
    variantGroups[gIndex].variants[index].prices[0][field] = targetItem;

    const data = getValues();
    const applyAll = data[`variantGroup[${gIndex}].apply_${field}`];
    if (applyAll) {
      const targetMeta = variantGroups[gIndex].meta;
      const targetVariant = variantGroups[gIndex].variants[index];
      variantGroups.forEach((g, i) => {
        if (g.meta.group === targetMeta.group) {
          const gVariantIndex = g.variants.findIndex(
            (v) =>
              v.type === targetVariant.type &&
              v.status === ProductStatus.PRE_ORDER
          );
          if (gVariantIndex !== -1) {
            variantGroups[i].variants[gVariantIndex][field] = targetItem;
            setValue(
              `variantGroup[${i}].variants[${gVariantIndex}].${field}`,
              targetItem
            );
          }
        }
      });
    }
    setVariantGroups([...variantGroups]);
  };

  const variantGroupPriceChange = (e, gIndex, index, field) => {
    const price = e.target ? e.target.value : e;
    variantGroups[gIndex].variants[index].prices[0][field] = price;

    const data = getValues();
    const applyAll = data[`variantGroup[${gIndex}].apply_${field}`];

    if (applyAll) {
      const targetMeta = variantGroups[gIndex].meta;
      const targetVariant = variantGroups[gIndex].variants[index];
      variantGroups.forEach((g, i) => {
        if (g.meta.group === targetMeta.group) {
          const gVariantIndex = g.variants.findIndex(
            (v) => v.type === targetVariant.type
          );
          if (gVariantIndex !== -1) {
            variantGroups[i].variants[gVariantIndex].prices[0][field] = price;
            setValue(
              `variantGroup[${i}].variants[${gVariantIndex}].prices[0].${field}`,
              price
            );
          }
        }
      });
    }
    setVariantGroups([...variantGroups]);
  };

  const variantGroupStatusChange = (e, gIndex, index) => {
    variantGroups[gIndex].variants[index].status = e.target.value;
    setVariantGroups([...variantGroups]);
  };

  const variantGroupQtyChange = (e, gIndex, index, field) => {
    const data = getValues();
    let reservedQty = 0;
    let onHandQty = 0;
    if (field === 'onHandQty') {
      onHandQty = parseFloat(e.target.value);
      reservedQty =
        parseFloat(
          data[`variantGroup[${gIndex}].variants[${index}].reservedQty`]
        ) || 0;
    } else {
      onHandQty =
        parseFloat(
          data[`variantGroup[${gIndex}].variants[${index}].onHandQty`]
        ) || 0;
      reservedQty = parseFloat(e.target.value);
    }
    const availableQty = onHandQty - reservedQty;
    setValue(
      `variantGroup[${gIndex}].variants[${index}].availableQty`,
      availableQty
    );

    variantGroups[gIndex].variants[index].availableQty = availableQty;
    setVariantGroups([...variantGroups]);

    variantGroupValueChange(e, gIndex, index, field);
  };

  const handleVariantGroupCollapse = (index) => {
    variantGroups[index].open = !variantGroups[index].open;
    setVariantGroups([...variantGroups]);
  };

  const handleEditVariantGroup = (variantGroup, gIndex) => {
    variantGroup.gIndex = gIndex;
    setSelectedVariantGroup(variantGroup);
    setOpenAddVariantModal(true);
  };

  const handleRemoveVariantGroup = (gIndex) => {
    variantGroups.splice(gIndex, 1);
    setVariantGroups([...variantGroups]);
  };

  const handleAddVarianGroupModal = () => {
    setOpenAddVariantModal(true);
    setSelectedVariantGroup(null);
  };

  const handleAddVariant = (gIndex) => {
    const newVariant = {
      rowId: uuidv4(),
      prices: [{ currency: 'SGD', amount: 0, compareAmount: 0 }],
      status: '',
      sku: '',
      onHandQty: 0,
      reservedQty: 0,
      availableQty: 0,
      type: '',
      statusType: '',
      subVariants: [],
    };
    if (isSubVariantProduct) {
      const { design, material, colour } = variantGroups[gIndex];
      newVariant.subVariants.push({
        key: ProductAssetKey.DESIGN,
        assetId: design.assetId,
        value: design.value,
        // image: design.image,
      });
      newVariant.subVariants.push({
        key: ProductAssetKey.MATERIAL,
        assetId: material.assetId,
        value: material.value,
        // image: material.image,
      });
      newVariant.subVariants.push({
        key: ProductAssetKey.COLOUR,
        assetId: colour.assetId,
        value: colour.value,
        // image: colour.image,
      });
      newVariant.statusType = variantGroups[gIndex].statusType;
      variantGroups[gIndex].variants.push(newVariant);
      setVariantGroups([...variantGroups]);
    } else {
      newVariant.statusType = StatusType.STANDARD;
      variantsState.push(newVariant);
      setVariantsState([...variantsState]);
    }
  };
  const handleRemoveVariant = (index, gIndex) => {
    if (isSubVariantProduct) {
      variantGroups[gIndex].variants.splice(index, 1);
      setVariantGroups([...variantGroups]);
    } else {
      variantsState.splice(index, 1);
      setVariantsState([...variantsState]);
    }
  };

  const handleAddProductAssetModal = () => {
    setOpenAddAssetModal(true);
    setSelectedProductAsset(null);
  };

  const openVariantGroupThumbnailModal = (gIndex) => {
    setSelectedVariantThumbnail({
      gIndex,
      thumbnail: variantGroups[gIndex].thumbnail || '',
    });
    setOpenThumbnailModal(true);
  };

  const openVariantThumbnailModal = () => {
    setSelectedVariantThumbnail({
      gIndex: -1,
      thumbnail: thumbnail.thumbnail || '',
    });
    setOpenThumbnailModal(true);
  };

  const handleAddhumbnail = (data) => {
    if (data.gIndex >= 0) {
      variantGroups[data.gIndex].thumbnail = data.path;
      variantGroups[data.gIndex].thumbnailFile = data.file;
      setVariantGroups([...variantGroups]);
    } else {
      thumbnail.thumbnail = data.path;
      thumbnail.thumbnailFile = data.file;
      setThumbnail({ ...thumbnail });
    }
    setOpenThumbnailModal(false);
  };

  const handleAddVariantGroup = (data) => {
    if (data.isNew) {
      if (
        variantGroups.find(
          (g) =>
            g.statusType === data.statusType &&
            g.design.assetId === data.design.assetId &&
            g.material.assetId === data.material.assetId &&
            g.colour.assetId === data.colour.assetId
        )
      ) {
        dispatch(
          warning('Duplicate material, design or colour!', {
            position: 'top-right',
          })
        );
        return;
      }
      data.variants = [];
      const subVariants = [];
      subVariants.push({
        key: ProductAssetKey.DESIGN,
        assetId: data.design.assetId,
        value: data.design.value,
        // image: data.design.image,
      });
      subVariants.push({
        key: ProductAssetKey.MATERIAL,
        assetId: data.material.assetId,
        value: data.material.value,
        // image: data.material.image,
      });
      subVariants.push({
        key: ProductAssetKey.COLOUR,
        assetId: data.colour.assetId,
        value: data.colour.value,
        // image: data.colour.image,
      });
      Object.keys(MattressVariants).forEach((key) => {
        data.variants.push({
          rowId: uuidv4(),
          prices: [{ currency: 'SGD', amount: 0, compareAmount: 0 }],
          status: '',
          sku: '',
          onHandQty: 0,
          reservedQty: 0,
          availableQty: 0,
          type: MattressVariants[key],
          statusType: data.statusType,
          subVariants,
        });
      });
      data.open = true;
      variantGroups.push(data);
    } else {
      if (
        variantGroups
          .filter((v, index) => index !== data.gIndex)
          .find(
            (g) =>
              g.statusType === data.statusType &&
              g.design.assetId === data.design.assetId &&
              g.material.assetId === data.material.assetId &&
              g.colour.assetId === data.colour.assetId
          )
      ) {
        dispatch(
          warning('Duplicate material, design or colour!', {
            position: 'top-right',
          })
        );
        return;
      }
      const variantGroup = variantGroups[data.gIndex];
      variantGroup.design = data.design;
      variantGroup.material = data.material;
      variantGroup.colour = data.colour;
      variantGroup.statusType = data.statusType;
      if (data.meta) variantGroup.meta = data.meta;
      if (variantGroup.variants) {
        variantGroup.variants.map((v) => {
          const materialSub = v.subVariants.find(
            (s) => s.key === ProductAssetKey.MATERIAL
          );
          if (materialSub) {
            materialSub.assetId = variantGroup.material.assetId;
            materialSub.value = variantGroup.material.value;
            // materialSub.image = variantGroup.material.image;
          }

          const designSub = v.subVariants.find(
            (s) => s.key === ProductAssetKey.DESIGN
          );
          if (designSub) {
            designSub.assetId = variantGroup.design.assetId;
            designSub.value = variantGroup.design.value;
            // designSub.image = variantGroup.design.image;
          }

          const colourSub = v.subVariants.find(
            (s) => s.key === ProductAssetKey.COLOUR
          );
          if (colourSub) {
            colourSub.assetId = variantGroup.colour.assetId;
            colourSub.value = variantGroup.colour.value;
            // colourSub.image = variantGroup.colour.image;
          }
          v.statusType = data.statusType;
          return v;
        });
      }
    }
    setVariantGroups([...variantGroups]);
    setOpenAddVariantModal(false);
  };

  const handleRemoveAsset = (assetId) => {
    let assetFound = false;
    for (let i = 0; i < variantGroups.length; i++) {
      if (variantGroups[i].variants) {
        assetFound = variantGroups[i].variants.some((v) =>
          v.subVariants.find((s) => s.assetId === assetId)
        );
      }
      if (assetFound) break;
    }
    if (assetFound) {
      dispatch(
        warning(
          'Unable to delete, asset has been found in one of the variant group.',
          {
            position: 'top-right',
          }
        )
      );
      return;
    }

    const aIndex = productAssets.findIndex((a) => a.assetId === assetId);
    if (aIndex !== -1) {
      productAssets.splice(aIndex, 1);
      setProductAssets([...productAssets]);
    }
  };

  const handleEditProductAsset = (asset) => {
    setSelectedProductAsset(asset);
    setOpenAddAssetModal(true);
  };

  const handleAddProductAsset = (data) => {
    const newData = {
      assetId: data.assetId,
      key: data.key,
      value: data.value,
      image: data.image,
      file: data.file,
    };
    if (data.isNew) productAssets.push(newData);
    else {
      const aindex = productAssets.findIndex(
        (a) => a.assetId === newData.assetId
      );
      productAssets[aindex] = newData;
    }
    setProductAssets([...productAssets]);

    if (newData.assetId) {
      variantGroups.forEach((variantGroup) => {
        if (variantGroup.variants) {
          variantGroup.variants.map((v) => {
            const subVariant = v.subVariants.find(
              (s) => s.assetId === newData.assetId
            );
            if (subVariant) {
              subVariant.key = newData.key;
              subVariant.value = newData.value;
              // subVariant.image = newData.image;
            }
            return v;
          });
        }
      });
    }
    setOpenAddAssetModal(false);
  };

  const handleAddVideoLink = () => {
    if (!videoPath) return;
    const attachedFile = {
      path: `https://www.youtube.com/embed/${videoPath}`,
      default: false,
      type: 'video',
    };
    setAttachedFiles(
      attachedFiles ? [...attachedFiles, attachedFile] : [attachedFile]
    );
    setVideoPath('');
  };
  const handleSelectFile = (event) => {
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    if (getFileType(file.name) !== 'image') {
      dispatch(warning('Invalid file type'));
      return;
    }
    if (!isFileSizeValid(file.size, ProductImage.MAX_SIZE)) {
      dispatch(
        warning(
          `Image size has been exceeded the limit of ${ProductImage.MAX_SIZE}KB`
        )
      );
      return;
    }

    readFileInBase64(file).then((res) => {
      const attachedFile = {
        file,
        default: true,
        ...res,
      };
      setAttachedFiles([attachedFile]);
    });
  };
  const handleRemoveFile = (index) => {
    setAttachedFiles(attachedFiles.filter((value, i) => i !== index));
  };

  const renderRatingStars = () => {
    if (!productState) return null;

    const { rating } = productState;
    if (!rating) return null;

    if (rating.avg === 0) return null;
    const stars = [];
    const remainder = rating.avg % 1;
    const fullStars = rating.avg - remainder;

    for (let i = 0; i < fullStars; ++i) stars.push('star');

    if (remainder > 0) stars.push('star_half');

    return (
      <span className="d-inline-block ml-3 text-warning">
        {stars.map((s, i) => (
          <MaterialIcon key={i} icon={s} />
        ))}
        <span className="d-inline-block ml-1">{rating.avg}</span>
      </span>
    );
  };

  const onDragStart = (event) => {
    const draggedFrom = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom,
      originalOrder: variantGroups,
    });

    // Note: this is only for Firefox. // Without it, the DnD won't work.
    event.dataTransfer.setData('text/html', '');
  };

  const onDragOver = (event) => {
    event.preventDefault();

    const draggedTo = Number(event.currentTarget.dataset.position);
    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        draggedTo,
      });
    }
  };

  const onDrop = () => {
    let newList = dragAndDrop.originalOrder;
    const { draggedFrom } = dragAndDrop;
    const { draggedTo } = dragAndDrop;

    newList = arrayMoveImmutable(newList, draggedFrom, draggedTo);
    setVariantGroups(newList);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
    });
  };

  const handleDelete = () => {
    dispatch(deleteProduct(productData.id));
  };

  const onSubmit = async (data) => {
    let variantsData = [];
    if (isSubVariantProduct) {
      data.variants = [];
      variantGroups.forEach((variantGroup) => {
        if (variantGroup.variants) {
          variantGroup.variants = variantGroup.variants.map((v) => {
            v.thumbnailFile = variantGroup.thumbnailFile;
            v.thumbnail = variantGroup.thumbnail;
            v.meta = variantGroup.meta;
            return v;
          });
          data.variants.push(...variantGroup.variants);
        }
        variantGroup.open = false;
      });
      variantsData = data.variants;
      setVariantGroups([...variantGroups]);

      data.assets = [];
      if (productAssets.length) {
        data.assets = await Promise.all(
          productAssets.map(async (a) => {
            if (a.file) {
              const fileRes = await uploadFile(
                a.file,
                `images/products/${id}/assets`
              );
              a.image = await getDownloadUrl(fileRes.metadata.fullPath);
            }
            delete a.file;
            return a;
          })
        );

        // update uploaded image path
        variantsData.forEach((v) => {
          v.subVariants.map((sub) => {
            // const asset = data.assets.find((a) => a.assetId === sub.assetId);
            // if (asset) sub.image = asset.image;
            delete sub.image;
            return sub;
          });
          return v;
        });
      }
    } else {
      variantsData = _.cloneDeep(variantsState);
      variantsData = variantsData.map((v) => {
        v.thumbnail = thumbnail.thumbnail || '';
        v.thumbnailFile = thumbnail.thumbnailFile;
        return v;
      });
    }

    if (attachedFiles) {
      await Promise.all(
        attachedFiles.map(async (attachedFile) => {
          if (!attachedFile.file) return;
          const fileRes = await uploadFile(
            attachedFile.file,
            `images/products/${id}`
          );
          attachedFile.path = await getDownloadUrl(fileRes.metadata.fullPath);
        })
      );
    }

    await Promise.all(
      variantsData.map(async (v) => {
        if (v.thumbnailFile) {
          const thumbnailRes = await uploadFile(
            v.thumbnailFile,
            `images/products/${id}/thumbnail`
          );
          v.thumbnail = await getDownloadUrl(thumbnailRes.metadata.fullPath);
        }
        delete v.thumbnailFile;
        return v;
      })
    );

    console.log('data', data);
    data.variants.map((v, i) => {
      v.prices[0].currency = 'SGD';
      v.prices[0].amount = parseFloat(v.prices[0].amount);
      v.prices[0].compareAmount = parseFloat(v.prices[0].compareAmount);
      v.onHandQty = parseFloat(v.onHandQty);
      v.reservedQty = parseFloat(v.reservedQty);
      v.availableQty = parseFloat(v.onHandQty - v.reservedQty);
      v.thumbnail = variantsData[i].thumbnail || '';
      v.subVariants = variantsData[i].subVariants || [];
      if (!v.id) v.id = uuidv4();
      if (!isSubVariantProduct) v.statusType = StatusType.STANDARD;
      delete v.rowId;
      return v;
    });

    const product = {
      ...productState,
      name: data.name,
      collection: data.collection,
      displayInStore: data.displayInStore,
      shippable: data.shippable,
      variants: data.variants,
      assets: data.assets || [],
      media: attachedFiles
        ? attachedFiles.map((attachedFile) => {
            return {
              type: attachedFile.type,
              path: attachedFile.path,
              default: true,
            };
          })
        : [],
    };
    console.log('product', product);
    delete product.id;
    if (id && canUpdate) dispatch(updateProduct(id, product));
    else if (!id && canCreate) dispatch(createProduct(product));
  };

  return (
    productState && (
      <MainStage
        title={!id ? 'New' : 'Update'}
        subtitle="Products"
        to="/products"
      >
        <Row>
          <Col md="12">
            <FormCard
              form
              title={
                !id ? (
                  'Enter product details'
                ) : (
                  <>
                    {productState.name}
                    {renderRatingStars()}
                  </>
                )
              }
              button={{ theme: 'primary', text: 'Save', isLoading }}
              onSubmit={handleSubmit(onSubmit)}
              onDelete={handleDelete}
            >
              <Row>
                <Col md="6">
                  <FormGroup
                    name="name"
                    label="Name"
                    control={control}
                    rules={{ required: 'Required field' }}
                    errors={errors}
                    defaultValue={productState.name}
                  />

                  <FormGroup
                    type="select"
                    options={productTypeOptions}
                    name="collection"
                    label="Collection"
                    control={control}
                    rules={{ required: 'Required field' }}
                    errors={errors}
                    defaultValue={productState.collection}
                  />

                  <Row>
                    <Col md="4">
                      <FormGroup
                        type="checkbox"
                        label="Display in store"
                        name="displayInStore"
                        control={control}
                        defaultValue={productState.displayInStore}
                      />
                    </Col>
                    <Col md="4">
                      <FormGroup
                        type="checkbox"
                        label="Shippable"
                        name="shippable"
                        control={control}
                        defaultValue={productState.shippable}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label>
                      Upload Image <small>1024px x 683px</small>
                    </label>
                    <FormInput
                      type="file"
                      onChange={(event) => handleSelectFile(event)}
                    />
                  </div>

                  <div className="form-group d-none">
                    <label id="TooltipExample">
                      Enter Youtube Video ID <MaterialIcon icon="error" />
                    </label>
                    <Tooltip
                      open={openTooltip}
                      target="#TooltipExample"
                      toggle={() => {
                        setOpenTooltip(!openTooltip);
                      }}
                    >
                      Click share on Youtube video and paste the highlighted ID:{' '}
                      <strong>
                        https://youtu.be/
                        <span className="hightlight-yellow">s934CItP0YA</span>
                      </strong>
                    </Tooltip>
                    <InputGroup>
                      <FormInput
                        value={videoPath}
                        onChange={(e) => setVideoPath(e.target.value)}
                      />
                      <InputGroupAddon type="append">
                        <Button
                          type="button"
                          onClick={handleAddVideoLink}
                          theme="primary"
                        >
                          Add
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>

                  <Row>
                    {attachedFiles &&
                      attachedFiles.map((attachedFile, index) => {
                        return (
                          <Col key={index} lg="6">
                            <Card className="product-card">
                              {attachedFile.type === 'image' && (
                                <CardImg top src={attachedFile.path} />
                              )}
                              {attachedFile.type === 'video' && (
                                <iframe
                                  className="card-img-top"
                                  title="woosa video"
                                  src={attachedFile.path}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen=""
                                />
                              )}
                              <CardBody>
                                <div className="d-flex">
                                  <Button
                                    className="mr-2"
                                    theme="success"
                                    type="button"
                                    outline={!attachedFile.default}
                                    // onClick={() => handleSetDefaultImage(index)}
                                  >
                                    Set default
                                  </Button>
                                  <DialogButton
                                    theme="danger"
                                    icon="delete_outline"
                                    message=""
                                    onConfirm={() => handleRemoveFile(index)}
                                    disabled={isLoading}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>

              <hr />

              {!isSubVariantProduct && (
                <ProductVariant
                  handleAddVariant={handleAddVariant}
                  handleRemoveVariant={handleRemoveVariant}
                  variantStatusChange={variantStatusChange}
                  variantValueChange={variantValueChange}
                  variantQtyChange={variantQtyChange}
                  variantPriceChange={variantPriceChange}
                  openVariantThumbnailModal={openVariantThumbnailModal}
                  variantsState={variantsState}
                  isLoading={isLoading}
                  thumbnail={thumbnail}
                  productStatusOptions={productStatusOptions}
                  control={control}
                  errors={errors}
                />
              )}

              {isSubVariantProduct && (
                <>
                  <ProductAssetTab
                    subVariantKeys={productData.subVariantKeys}
                    productAssets={productAssets}
                    onRemove={handleRemoveAsset}
                    onEdit={handleEditProductAsset}
                    handleAddProductAssetModal={handleAddProductAssetModal}
                  />

                  <hr />

                  <ProductSubVariant
                    control={control}
                    errors={errors}
                    variantGroups={variantGroups}
                    productStatusOptions={productStatusOptions}
                    customProductStatusOptions={customProductStatusOptions}
                    productAssets={productAssets}
                    isLoading={isLoading}
                    onDragOver={onDragOver}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    handleAddVariant={handleAddVariant}
                    handleRemoveVariant={handleRemoveVariant}
                    handleRemoveVariantGroup={handleRemoveVariantGroup}
                    handleEditVariantGroup={handleEditVariantGroup}
                    handleVariantGroupCollapse={handleVariantGroupCollapse}
                    variantGroupStatusChange={variantGroupStatusChange}
                    variantGroupValueChange={variantGroupValueChange}
                    variantGroupQtyChange={variantGroupQtyChange}
                    variantGroupPriceChange={variantGroupPriceChange}
                    handleAddVarianGroupModal={handleAddVarianGroupModal}
                    openVariantGroupThumbnailModal={
                      openVariantGroupThumbnailModal
                    }
                    variantGroupDateNoteChange={variantGroupDateNoteChange}
                  />
                </>
              )}
            </FormCard>
          </Col>
        </Row>

        {customVariantProductIds.includes(id) && enableSubVariant && (
          <>
            <VariantGroupModal
              subVariantKeys={productData.subVariantKeys}
              productAssets={productAssets}
              variantGroup={selectedVariantGroup}
              open={openAddVariantModal}
              onClose={() => setOpenAddVariantModal(false)}
              onAddVariantGroup={handleAddVariantGroup}
            />

            <ProductAssetModal
              productAsset={selectedProductAsset}
              open={openAddAssetModal}
              onClose={() => setOpenAddAssetModal(false)}
              onAddProductAsset={handleAddProductAsset}
            />
          </>
        )}

        <VariantThumbnailModal
          variant={selectedVariantThumbnail}
          open={openThumbnailModal}
          onClose={() => setOpenThumbnailModal(false)}
          onAddThumbnail={handleAddhumbnail}
        />
      </MainStage>
    )
  );
};

export default ProductForm;
